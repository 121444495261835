import React from 'react'
import { graphql } from 'gatsby'
import Page from '../components/Page'

export default function ServiceTemplate({ data: { sanityService } }) {
  const { title, hero, lowKeyHero, modules } = sanityService

  return (
    <Page title={title} hero={hero} lowKeyHero={lowKeyHero} modules={modules} />
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityService(slug: { current: { eq: $slug } }) {
      title
      hero {
        ...HeroFields
      }
      lowKeyHero {
        ...LowKeyHeroFields
      }
      modules {
        ... on SanityRichTextModule {
          ...RichTextModuleFields
        }
        ... on SanityGalleryModule {
          ...GalleryModuleFields
        }
        ... on SanityTextWithGalleryModule {
          ...TextWithGalleryModuleFields
        }
        ... on SanityTextWithImageModule {
          ...TextWithImageModuleFields
        }
        ... on SanityQuoteModule {
          ...QuoteModuleFields
        }
      }
    }
  }
`
